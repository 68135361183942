*{box-sizing:border-box}
.inputNresultsCompSection{
background-color:;
min-height:5rem;
padding-top:0%;
padding-bottom:0rem
}
.inputNresultsCompSectionHeader{
background-color:;
padding-top:8%;
padding-bottom:0rem
}
.inputNresultsComp{
width:45%
}
.inputNresultsCompLongChar{
width:55%
}
.inputNresultsComp_h1{
font-size:2.2rem;
margin:0;
padding:0;
font-weight:400;
color:rgb(245, 46, 46)
}
.inputNresultsComp_h3{
font-size:1.1rem;
font-weight:400;
color:rgb(245, 46, 46)
}
.inputNbuttonContainer{
margin-top:0rem
}
.inputNbuttonFrame{
position:relative;
width:auto;
height:4rem;
padding:0.3rem;
display:flex;
align-items:center;
justify-content:left;
border-radius:0.3rem;
border:2px solid rgb(245, 46, 46);
box-shadow: 0px 8px 10px -3px rgba(0,0,0,0.1);
}
.inputFrame{
display:flex;
height:100%;
width:70%
}
.inputFrameLongChar{
display:flex;
height:100%;
width:65%
}
.inputFrameArab{
display:flex;
height:100%;
width:70%	
}
.input{
width:100%;
height:100%;
font-size:1.2rem;
border:0;
padding-left:0.4rem;
padding-right:0.4rem;
border-radius:0.3rem;
color:rgba(245, 46, 46,0.8)
}
.input::placeholder{
color:rgba(245, 46, 46,0.6)
}
.input:focus{
outline:none
}
.DownloadbuttonContainer{
margin:0;
width:30%;
height:100%;
display:flex;
}
.DownloadbuttonContainerLongChar{
margin:0;
width:35%;
height:100%;
display:flex;
}
.DownloadbuttonContainerArab{
margin:0;
width:30%;
height:100%;
display:flex;
}
.Downloadbutton{
background-color:rgb(245, 46, 46);
color:white;
width:100%;
height:100%;
font-size:1.3rem;
padding:6% 12% 6% 12%;
border-radius:0.3rem;
display:flex;
justify-content:center;
align-items:center;
border:0;
}
.DownloadText{
border:0;
}
.DownloadbuttonIcon{
font-size:2.1rem;
display:flex;
justify-content:center;
align-items:center
}
.inputResultField{
position:absolute;
width:100%;
top:105%;
left:0%;
z-index:5;
background-color:white;
box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.1);
border-radius:0rem 0rem 0.3rem 0.3rem;
padding-bottom:1rem
}
.inputResultList{
border:1px solid rgba(204,204,204,0.3);
color:gray;
width:28%;
min-height:5.5rem;
margin:2%;
font-size:0.7rem;
border-radius:0.3rem;
padding-bottom:0.3rem;
overflow:hidden
}
.inputResultList:hover{
box-shadow: 0px 10px 15px -10px rgba(0,0,0,0.1)
}
.sprite{
width:auto;
height:1rem;
margin-top:0.5rem;
color:white
}
.downLoadVideoSection{
margin-top:2rem
}
.downLoadVideoSectionHeader{
color:rgb(41, 11, 135);
font-size:1.1rem;
font-weight:500;
margin-bottom:1rem
}
.downLoadVideoSection_download_frame{
width:65%;
padding:0.2rem 0.2rem 0.2rem 0.2rem;
display:flex
}
.downLoadVideoSection_download_L{
flex:0.45;
padding:0rem 0.5rem 1rem 0.5rem;
color:rgb(41, 11, 135);
padding-top:0.6rem
}
.downLoadVideoSection_download_R{
flex:0.55;
padding:0rem 1rem 1rem 1rem;
}
.downLoadVideoSection_download_R_header{
display:flex;
justify-content:center;
align-items:center;
padding:0.2rem;
color:rgb(41, 11, 135);
border-top:1px solid rgb(60, 60, 197); 
border-left:1px solid rgb(60, 60, 197); 
border-right:1px solid rgb(60, 60, 197); 
border-bottom:0px solid rgb(60, 60, 197)
}
.downLoadVideoSection_download_R_header_youtube_icon{
margin-right:7px;
font-size:1.9rem;
}
.downLoadVideoSection_download_R_header_text{
font-size:1.4rem
}
.downLoadVideoSection_download_L_image_frame{
width:100%;
min-height:11.5rem;
border-radius:0.3rem;
overflow:hidden;
line-height:85%;
}
.downLoadVideoSection_download_L_title{
margin-top:0.3rem;
min-height:1.7rem
}
.saveLink{
background-color:rgb(41, 11, 135);
color:white;
width:fit-content;
height:100%;
font-size:1rem;
padding:6.5% 5% 6.5% 5%;
border:0;
cursor:pointer;
border-radius:0.3rem;
box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.1)
}
.downLoadVideoSection_download_R_List{
display:flex;
padding:0.6rem 0.9rem 0.6rem 0.9rem;
color:rgb(41, 11, 135);
border-top:1px solid rgb(55, 55, 147); 
border-left:1px solid rgb(60, 60, 197); 
border-right:1px solid rgb(60, 60, 197); 
border-bottom:1px solid rgb(60, 60, 197)
}
.downLoadVideoSection_download_R_List:not(:last-child){
border-bottom:0px solid blue
}
.downLoadVideoSection_download_R_mediaFormat{
text-align:left;
display:flex;
align-items:center;
position:relative
}
.downLoadVideoSection_download_R_size{
text-align:left;
display:flex;
align-items:center
}
.downLoadVideoSection_download_R_size_sm{
position:absolute;
display:none
}
.downLoadVideoSection_download_R_DownloadButtonSpan{
display:flex;
margin-left:auto
}
.downLoadVideoSection_download_R_DownloadButton{
background-color:rgb(41, 11, 135);
padding:0.6rem 1.2rem 0.6rem 1.2rem;
border-radius:0.4rem;
display:flex;
color:white;
border:0;
align-items:center;
cursor:pointer;
transition:0.4s ease-in
}
.downLoadVideoSection_download_R_DownloadButton:hover{
padding-left:2rem;
transition:0.4s ease-in;
}
.DownloadIconn_R{
margin:0;
padding:0	
}
.DownloadText_R{
padding-left:0.5rem;
}


@media screen and (max-width: 1030px) {
.inputNresultsCompSection{
min-height:5vw;
padding-top:0%
}
.inputNresultsCompSectionHeader{
background-color:;
padding-top:17%;
padding-bottom:0rem
}

.inputNbuttonFrame{
height:8vw
}
.inputNresultsComp{
width:70%;
margin:auto
}
.inputNresultsCompLongChar{
width:70%
}
.inputNresultsComp_h1{
font-size:4.5vw;
font-weight:400;
}	
.Downloadbutton{
font-size:2.2vw;
padding:7% 7% 7% 12%;
border-radius:0.3rem;
display:flex;
justify-content:center;
align-items:center
}
.DownloadbuttonIcon{
font-size:4vw;
}
.sprite{
width:auto
}
.downLoadVideoSection_download_L_image_frame{
min-height:18vw
}
.downLoadVideoSection_download_frame{
width:80%;
padding:0.2rem 0.2rem 0.2rem 0.2rem;
display:flex;
}
.downLoadVideoSection_download_R_List{
display:flex;
padding:0.6rem 0.6rem 0.6rem 0.6rem
}
.downLoadVideoSection_download_R_mediaFormat{
text-align:left;
display:flex;
align-items:center;
}
.downLoadVideoSection_download_R_DownloadButtonSpan{
display:flex;
}
.downLoadVideoSection_download_L_title{
font-size:2.25vw
}
.downLoadVideoSection_download_L_author,.downLoadVideoSection_download_L_duration{
margin-top:0.3rem;
font-size:2.1vw
}
.DownloadText_R{
padding-left:0.5rem;
font-size:1.8vw
}
.inputFrame{
display:flex;
height:100%;
width:65%
}
.inputFrameLongChar{
display:flex;
height:100%;
width:65%
}
.DownloadbuttonContainer{
margin:0;
width:35%;
height:100%;
display:flex;
}
.DownloadbuttonContainerLongChar{
margin:0;
width:35%;
height:100%;
display:flex;
}
.saveLink{
width:fit-content;
height:100%;
font-size:1rem;
padding:8% 5% 8% 5%;
border:0;
cursor:pointer;
border-radius:0.3rem;
box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.1)
}
}

@media screen and (max-width: 768px) {
.DownloadbuttonContainer{
margin:0;

}	
.Downloadbutton{
font-size:2.2vw;
padding:7% 7% 7% 12%;
border-radius:0.3rem;
display:flex;
justify-content:center;
align-items:center
}
}

@media screen and (max-width: 550px) {
.DownloadText{
display:none
}
.inputNresultsCompSection{
min-height:4vw;
padding-top:0%;
padding-bottom:1vw
}
.inputNresultsCompSectionHeader{
background-color:;
padding-top:25%;
padding-bottom:0rem
}
.inputNresultsComp{
width:95%;
margin:auto;
}
.inputNresultsCompLongChar{
width:95%
}
.inputNresultsComp_h1{
font-size:5.5vw;
font-weight:400
}
.inputNresultsComp_h3{
font-size:3.5vw;
font-weight:400;
padding:0rem 0rem 0rem 0rem
}
.inputNbuttonContainer{
margin-top:0rem
}
.inputNbuttonFrame{
position:relative;
width:90%;
height:15vw
}
.inputFrame{
display:flex;
height:100%;
width:83%;
}
.inputFrameLongChar{
display:flex;
height:100%;
width:83%
}
.input{
width:100%;
padding-left:3%;
padding-right:7%;
border-radius:0.3rem;
color:rgba(41, 11, 135,0.8);
}
.DownloadbuttonContainer{
margin:0;
width:17%
}
.DownloadbuttonContainerLongChar{
margin:0;
width:17%;
}
.Downloadbutton{
border-radius:0.3rem;
}
.DownloadbuttonIcon{
font-size:9vw
}
.sprite{
width:auto;
font-size:4vw;
line-height:100%
}
.downLoadVideoSection{
margin-top:1rem;
}
.downLoadVideoSection_download_frame{
width:85%;
padding:0.1rem 0.2rem 0.2rem 0.2rem;
display:flex;
flex-direction:column;
}
.downLoadVideoSection_download_L{
flex:0.45;	
}
.downLoadVideoSection_download_R{
flex:0.55
}
.downLoadVideoSection_download_R_header{
display:flex;
justify-content:center;
align-items:center;
}
.downLoadVideoSection_download_R_header_youtube{
margin-right:7px;
font-size:8.5vw
}
.downLoadVideoSection_download_R_header_text{
font-size:6vw
}
.downLoadVideoSection_download_R{
flex:0.55;
padding:1rem 0rem 1rem 0rem
}
.downLoadVideoSection_download_R_List{
display:flex;
padding:3vw 1vw 3vw 1vw
}
.downLoadVideoSection_download_L_title{
font-size:4.3vw
}
.downLoadVideoSection_download_L_author,.downLoadVideoSection_download_L_duration{
margin-top:0.3rem;
font-size:4.1vw
}
.inputResultList{
border:1px solid white;
color:gray;
width:40%;
margin:2%;
font-size:0.7rem;
padding-bottom:2vw
}
.saveLink{
width:fit-content;
height:100%;
font-size:1rem;
padding:8% 5% 8% 5%;
border-radius:0.3rem
}
.DownloadText_R{
padding-left:0.5rem;
font-size:3.2vw
}
.downLoadVideoSection_download_L{
padding-top:0
}
.downLoadVideoSection_download_R_DownloadButton{
padding:0.7rem 1.2rem 0.7rem 1.2rem;
border-radius:0.4rem;
display:flex;
display:flex;
border:0;
align-items:center;
}
.inputResultField{
padding-bottom:1.5rem
}

}
@media screen and (max-width: 450px) {
.inputNbuttonFrame{
position:relative;
width:90%;
height:16vw
}
}
@media screen and (max-width: 300px) {
.inputNbuttonFrame{
position:relative;
width:90%;
height:18vw
}
.DownloadText_R{
padding-left:0.5rem;
font-size:4vw
}
.downLoadVideoSection_download_R_size{
display:none
}
.downLoadVideoSection_download_R_size_sm{
position:absolute;
top:-20%;
font-size:4vw;
display:block
}
.downLoadVideoSection_download_R_mediaFormat{
font-size:4.5vw
}
}