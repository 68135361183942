*{box-sizing:border-box}

.headerContainer{
width:100%;
height:5rem;
display:flex;
align-items:center;
justify-content:center
}
.header_Inner_container{
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
width:90%;
height:100%;
gap:0vw;

}

.vl {
  height: 10px;
}
.o_name{
margin-bottom:0rem;
}
.ul_parent{
  margin: 0px;
  list-style:none;
  display: flex;
  flex-direction: row;
  text-align: center;     
  font-size:0.85rem;
  padding-top:0.20rem;
  margin:0px;
  font-weight:550;
  height:100%;
}


.HeaderMobile{
display: none
}

.social_ul_parent_Lg{
gap:1.7vw;
transition:0.5s;
}
.social_ul_parent_Lg > li{
transition:0.5s;
}

.social_ul_a_tag{
font-size:0.79rem;
transition-delay:5s ;
}
.social_ul_a_tag :hover{
color:var(--social-hover-fx-color);
transition:0.5s ;
}
.logo_frame{
display:flex;
align-items:center;
justify-content:center;
width:7%;
height:auto
}
.logo_frame_ar{
display:flex;
align-items:center;
justify-content:center;
margin-left:2%;
width:7%;
height:auto	
}
.languageTrans{
position:relative;
}
.selectedLanguage{
color:white;
width:fit-content;
font-size:2rem;
}
.languageList{
color:white;
padding-top:0.7em;
padding-bottom:0.7em;
position:absolute;
width:13rem;
right:-2%;
top:120%;
z-index:5;
border-radius:0.3em;
background-color:white;
box-shadow: 0px 6px 63px -7px rgba(0,0,0,0.1);
}
.languageListArab{
color:white;
padding-top:0.7em;
padding-bottom:0.7em;
position:absolute;
width:13rem;
left:-2%;
top:120%;
z-index:5;
border-radius:0.3em;
background-color:white;
box-shadow: 0px 6px 63px -7px rgba(0,0,0,0.1);	
}
.languageList_langs{
padding:0.5rem 0.1rem 0.5rem 1.5rem ;
color:black
}
.languageList_langs:hover{
background-color:rgb(41, 11, 135);
color:white;

}

@media screen and (max-width: 920px) {
.HeaderMobile{
display: none
}
.header_Inner_container{
display:flex;
flex-direction:row;
width:93%;
height:100%;

gap:0vw;
}
.o_name{
margin-bottom:0rem
}

.headerContainer{
width:100%;
height:5rem;
}
.social_ul_parent_Lg{
gap:0vw;
}
.ul_parent{
  padding-top:0.45vw;
}

.company_name{

}
.langComp{

}
.logo_frame{
display:flex;
align-items:center;
justify-content:center;
width:10%;
}
.logo_frame_ar{
display:flex;
align-items:center;
justify-content:center;
margin-left:4%;
width:10%;
}

}

@media screen and (max-width: 550px) {
.HeaderMobile{
display: block;
top:0;
width:100%;
z-index:5;
}	
.HeaderMdLg{
display: none
}	
.headerContainerSm{
width:100%;
height:17vw;
padding-left:15px;
padding-right:20px;
padding-top:5vw;
padding-bottom:5vw;
display:flex;
align-items:center;
}
.headerNameSm{
font-size:9vw;
font-weight:bold;	
padding: 0;
text-transform:uppercase;
display:flex;
align-items:center;
justify-content:center;
border:1px solid red
}

.ul_container_mobile{
height:70%;
padding-top:1rem;
padding-left:0.15rem
}
.mobile_social_icons{
color:var(--mobile-social-color);
margin:10vw
}
.mobile_social_icons:hover{
color:var(--mobile-social-hover-fx-color)
}
#header_main_Container_shadow_mobile{
box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.1);
}
.hamburger_menu_name{

}
.hamburger_menu{
margin-left:8vw;
margin-bottom:5vw
}
.logo_frame{
display:flex;
align-items:center;
justify-content:center;
width:100%;
height:auto
}
.logo_frame_ar{
display:flex;
align-items:center;
justify-content:center;
margin-left:4%;
width:8%;
height:auto
}
.selectedLanguage{
color:white;
width:fit-content;
font-size:2rem;
height:10%
}
.headerInerContainer{
width:87%;
margin:auto;
height:17vw;
display:flex;
align-items:center
}
.languageList{
color:white;
padding-top:0.7em;
padding-bottom:0.7em;
position:absolute;
width:13rem;
top:1200%;
z-index:5;
border-radius:0.3em;
background-color:white;
box-shadow: 0px 6px 63px -7px rgba(0,0,0,0.1);
}
.languageListArab{
color:white;
padding-top:0.7em;
padding-bottom:0.7em;
position:absolute;
width:13rem;
left:-2%;
top:1200%;
z-index:5;
border-radius:0.3em;
background-color:white;
box-shadow: 0px 6px 63px -7px rgba(0,0,0,0.1);	
}
}