*{box-sizing:border-box}
.footer_main_container{
height:20rem;
padding-bottom:4rem;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
}
.footer_main_container_top{
width:93%;
height:69%;
padding-top:0.6rem;
display:flex;
align-items:center;
justify-content:center;
}
.footer_main_container_top_services{
height:100%;
font-size:1em;
display:flex;
align-items:center;
justify-content:center;
flex-wrap:wrap
}
.footer_main_container_top_contact{
width:43%;
height:100%;
font-size:1em;
display:flex;
align-items:center;
justify-content:center;
}
.footer_main_container_top_social{
width:27%;
height:100%;
font-size:1em;
display:flex;
align-items:center;
justify-content:right;
}
.footer_main_container_bottom{
width:93%;
height:31%;
display:flex;
align-items:center;
justify-content:center;
font-size:0.8em;
padding-top:0.3rem;
}
.footer_main_container_top_social_li{
padding-left:0.92rem;
padding-right:0.92rem
}
.footer_main_container_top_services_links{
margin:0.5rem
}
.footer_main_container_top_menu_links{
margin:0.5rem;
padding:0.5rem
}
.footer_main_container_top_menu{
height:100%;
font-size:0.8em;
display:flex;
align-items:center;
justify-content:center;
flex-wrap:wrap
}
.footer_main_container_top_menu_ipad{
display:none
}
.footer_main_container_top_menu_ipad_L{
display:none;
}
.footer_main_container_top_services_mobile{
display:none	
}
.scrollTopComp{
font-size:3rem;
color:rgb(245, 46, 46);
display:flex;
width:fit-content;
position:fixed;
top:90%;
right:1%
}

@media screen and (max-width: 950px) {
.footer_main_container{
height:30rem;
width:100%;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
}
.footer_main_container_top{
width:90%;
padding-top:0.6rem;
display:flex;
align-items:center;
justify-content:center;
}
.footer_main_container_top_services{
width:100%;
font-size:2.5vw;
display:none
}
.footer_main_container_top_contact{
width:100%;
height:100%;
font-size:1.8vw;
display:flex;
align-items:center;
justify-content:center;
}
.footer_main_container_top_social{
width:27%;
height:100%;
font-size:2.5vw;
display:flex;
align-items:center;
justify-content:right;
text-align:right;
}
.footer_main_container_bottom{
width:98%;
height:31%;
display:flex;
align-items:center;
justify-content:center;
font-size:0.8em;
padding-top:0.3rem;
}
.footer_main_container_top_social_li{
padding-left:0.5rem;
padding-right:0.5rem
}	
.footer_main_container_top_contact_md{
display:none
}
.footer_main_container_top_services_links{
margin:0.5rem;
width:100%;
}
.footer_main_container_top_menu_links{
margin:0.9rem;
}
.footer_main_container_top_menu{
height:100%;
font-size:2.3vw;
display:flex;
align-items:center;
justify-content:center;
flex-wrap:wrap
}
.footer_main_container_top_services_mobile{
text-align:center;
display:block	
}
.scrollTopComp{
top:90%;
right:2%
}

}


@media screen and (max-width: 550px) {
.footer_main_container{
height:28rem;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
}
.footer_main_container_top{
width:98%;
height:100vw;
padding-top:0.6rem;
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
}
.footer_main_container_top_services{
width:100%;
font-size:4vw;
text-align:center;
display:none
}
.footer_main_container_top_contact{
width:100%;
height:100%;
font-size:3.8vw;
display:flex;
align-items:center;
justify-content:center;
margin-bottom:5vw;
}
.footer_main_container_top_social{
width:100%;
height:100%;
font-size:6vw;
display:flex;
align-items:center;
justify-content:center;
margin-bottom:5vw;
}
.footer_main_container_bottom{
width:98%;
height:31%;
display:flex;
align-items:center;
justify-content:center;
font-size:4vw;
padding-top:0.3rem;
}
.footer_main_container_top_social_li{
padding-left:0.5rem;
padding-right:0.5rem
}	
.footer_main_container_top_contact_md{
display:none
}
.footer_main_container_top_services_links{
margin:0.5rem;
width:100%
}
.footer_main_container_top_menu_links{
margin:0.5rem;
padding:0.7rem
}
.footer_main_container_top_menu{
width:100%;
width:98%;
font-size:3.5vw;
display:flex;
flex-wrap:wrap;
display:block;
}
.footer_main_container_top_menu_ipad{
display:none
}
.footer_main_container_top_menu_ipad_L{
display:none;
}
.footer_main_container_top_services_mobile{
text-align:center;
display:block	
}
.footer_main_container_top_services_mobile > div{
padding: 1vw 0vw 1vw 0vw
}
.scrollTopComp{
top:90%;
right:1.1%
}
}